// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internacional-agromanagement-js": () => import("./../../../src/pages/internacional/Agromanagement.js" /* webpackChunkName: "component---src-pages-internacional-agromanagement-js" */),
  "component---src-pages-internacional-eco-logicas-js": () => import("./../../../src/pages/internacional/EcoLogicas.js" /* webpackChunkName: "component---src-pages-internacional-eco-logicas-js" */),
  "component---src-pages-internacional-gestion-de-proyectos-js": () => import("./../../../src/pages/internacional/GestionDeProyectos.js" /* webpackChunkName: "component---src-pages-internacional-gestion-de-proyectos-js" */),
  "component---src-pages-internacional-js": () => import("./../../../src/pages/internacional.js" /* webpackChunkName: "component---src-pages-internacional-js" */),
  "component---src-pages-internacional-mercado-capitales-js": () => import("./../../../src/pages/internacional/MercadoCapitales.js" /* webpackChunkName: "component---src-pages-internacional-mercado-capitales-js" */),
  "component---src-pages-oferta-academica-js": () => import("./../../../src/pages/oferta-academica.js" /* webpackChunkName: "component---src-pages-oferta-academica-js" */),
  "component---src-templates-area-js": () => import("./../../../src/templates/area.js" /* webpackChunkName: "component---src-templates-area-js" */),
  "component---src-templates-course-js": () => import("./../../../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-training-type-js": () => import("./../../../src/templates/trainingType.js" /* webpackChunkName: "component---src-templates-training-type-js" */)
}

